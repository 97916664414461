// react | next
import Link from 'next/link';
// interface | types
import type { IFooterMenu } from '@components/Menu/Footer/IFooterMenu';
// components
import { MenuLink } from '../MenuLink';
import { LogoFullPrimaryLight } from '@components/Svgs/LogoFullPrimaryLight/LogoFullPrimaryLight';
// styles
import styles from '../Menu.module.scss';

export const FooterMenu = ({ isMobile, links }: IFooterMenu) => {
	return (
		<nav className={styles.navbar} role='navigation' aria-label='Site Navigation'>
			<div className={styles.navbar_content}>
				<div className={`${styles.navbar_menu} ${isMobile ? styles.mobile : styles.hidden}`}>
					<ul className={`container ${styles.navbar_nav}`}>
						{links?.map(({ path, title }) => (
							<MenuLink path={path} title={title} key={title} />
						))}
					</ul>
				</div>
				<div className={styles.navbar_brand}>
					<Link href='/'>
						<a title='Navigate to Home Page'>
							<LogoFullPrimaryLight height='65px' />
						</a>
					</Link>
				</div>
			</div>
		</nav>
	);
};
