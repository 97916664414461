// react | next
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
// sanity
import { urlFor } from '@lib/sanity';
import { getImageDimensions, getExtension } from '@sanity/asset-utils';
// third-party
import { useCookies } from 'react-cookie';
// interfaces
import type { IBaseLayout } from './IBaseLayout';
import { getAppUrl } from '@utils/functions/getAppUrl';
import { useEffect } from 'react';

export const BaseLayout = ({ seo, children }: IBaseLayout) => {
	const { asPath, query } = useRouter();

	// Check for utm_source and set cookie if it exists
	const [utmSourceCookie, setUtmSourceCookie] = useCookies(['utm_source']);
	const { utm_source } = query;

	useEffect(() => {
		if (utm_source) {
			setUtmSourceCookie('utm_source', utm_source, { maxAge: 60 * 60 * 24 * 30 });
		}
	}, [utm_source]);

	console.log(utm_source, "utm_source baselayout line 28")
	console.log(utmSourceCookie, "utmSourceCookie baselayout line 29")
	console.log(query, "query baselayout line 30")

	const url = `${getAppUrl()}${asPath}`;

	const imageDetails = getImageDetails(seo?.image);

	const title = seo?.title ? `${seo.title} | DriveEighteen` : 'DriveEighteen';

	const noIndex = seo?.noIndex ?? false;

	return (
		<>
			<Head>
				<meta charSet='UTF-8' />
				<meta httpEquiv='X-UA-Compatible' content='IE=edge' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />

				{/* SEO */}
				<meta name='title' content={title} />
				{noIndex ? <meta name='robots' content='noindex' /> : null}
				{seo?.description ? (
					<>
						<meta name='description' content={seo.description} />
						<meta property='og:description' content={seo.description} />
					</>
				) : null}
				<meta property='og:title' content={title} />
				<meta property='og:type' content='website' />
				<meta property='og:url' content={url} />
				{imageDetails ? (
					<>
						<meta property='og:image' content={imageDetails.url} />
						<meta property='og:image:height' content={imageDetails.height.toString()} />
						<meta property='og:image:width' content={imageDetails.width.toString()} />
						<meta property='og:image:type' content={`image/${imageDetails.type}`} />
					</>
				) : null}
				<meta property='twitter:card' content='summary_large_image' />
				{/* END SEO */}

				<meta name='format-detection' content='telephone=no' />

				{/* Page Title */}
				<title>{title}</title>
			</Head>
			{process.env.NEXT_PUBLIC_GTM_ID && (
				<Script id='google-tag-manager' strategy='afterInteractive'>
					{`
					  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
					`}
				</Script>
			)}
			{children}
		</>
	);
};

const getImageDetails = (image: string) => {
	if (!image) {
		return;
	}

	const sanityImage = urlFor(image).url();

	if (!sanityImage) {
		return;
	}

	const { width, height } = getImageDimensions(sanityImage);
	const type = getExtension(sanityImage);

	return {
		url: sanityImage,
		width,
		height,
		type,
	};
};
