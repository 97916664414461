// interface | types
import type { IMenu } from '@components/Menu/IMenu';

interface IData {
	title: string;
	menu: IMenu;
}

export const data: IData = {
	title: 'DriveEighteen',
	menu: {
		links: [
			{
				title: 'Search',
				path: '/locations',
			},
			{
				title: 'Jobs',
				path: '/jobs',
			},
			{
				title: 'Hiring Events',
				path: '/hiring-events',
			},
			{
				title: 'Carriers',
				path: '/carriers',
			},
			{
				title: 'Advertise',
				path: '/advertise',
			},
			{
				title: 'Blog',
				path: '/posts',
			},
		],
	},
};
