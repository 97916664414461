// react | next
import { useRouter } from 'next/router';
import Link from 'next/link';
// interface | types
import type { IMenuLink } from './IMenuLink';
// styles
import styles from './Menu.module.scss';

export const MenuLink = ({ path, title, onClick }: IMenuLink) => {
	const router = useRouter();

	return (
		<li className={`${styles.nav_item} ${router.pathname === path ? styles.active : ''}`}>
			<Link href={path}>
				<a className={styles.nav_link} onClick={onClick} aria-label={`Navigate to ${title} page`}>
					{title}
				</a>
			</Link>
		</li>
	);
};
