// react | next
import Link from 'next/link';
// interface | types
import type { IFooter } from './IFooter';
// components
import { FooterMenu } from '@components/Menu/Footer/FooterMenu';
// styles
import styles from './Footer.module.scss';

export const Footer = ({ menu, title }: IFooter) => {
	return (
		<footer id='site-footer' className={styles.footer_wrapper}>
			<div className={styles.footer_top}>
				<div className='container'>
					<FooterMenu isMobile={false} links={menu?.links} />
				</div>
			</div>
			<div className={styles.footer_bottom}>
				<div className='container'>
					<p className={styles.footer_copyright}>
						Copyright &copy; {new Date().getFullYear()} {title} | {''}
						<Link href='/privacy-policy'>
							<a className={styles.privacy_link}>Privacy</a>
						</Link>
					</p>
				</div>
			</div>
		</footer>
	);
};
