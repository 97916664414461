// layout
import { BaseLayout } from './BaseLayout';
// components
import { Header } from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
// styles
import styles from './PageLayout.module.scss';
import type { IPageLayout } from './IPageLayout';
import { CookiesProvider } from 'react-cookie';

export const PageLayout = ({ layout, fill, children, seo }: IPageLayout) => {
	return (
		<CookiesProvider>
			<BaseLayout seo={seo}>
				<div className={styles.pageWrapper}>
					<Header menu={layout?.menu} fill={fill} />
					<main id='main-content' data-content='main' className={styles.mainContentWrapper}>
						<div className={styles.mainContent}>{children}</div>
					</main>
					<Footer menu={layout?.menu} title={layout?.title} />
				</div>
			</BaseLayout>
		</CookiesProvider>
	);
};
