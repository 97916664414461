// react | next
import { useEffect, useState } from 'react';
// third-party
import { debounce } from 'throttle-debounce';
import FocusTrap from 'focus-trap-react';
// interface | types
import type { IHeader } from './IHeader';
// components
import { HeaderMenu } from '@components/Menu/Header/HeaderMenu';
// styles
import styles from './Header.module.scss';

export const Header = ({ fill, menu }: IHeader) => {
	const [activeMenu, setActiveMenu] = useState(false);

	const toggleMenu = () => {
		setActiveMenu(!activeMenu);
	};

	// prettier-ignore
	useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 992px)');
		const handleResize = () => {
			if (mediaQuery.matches) {
				setActiveMenu(false);
			}
		};
		const debouncedHandleResize = debounce(150, handleResize);

		const handleEscape = (e) => {
			if (e.key == 'Escape') {
				toggleMenu();
			}
		}
		
		if (activeMenu) {
			document.body.classList.add('overflow-hidden');
			window.addEventListener('resize', debouncedHandleResize);
			window.addEventListener('keydown', handleEscape);
		} else {
			document.body.classList.remove('overflow-hidden');
		}
		
		return () => {
			window.removeEventListener('resize', debouncedHandleResize);
			window.removeEventListener('keydown', handleEscape);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeMenu]);

	return (
		<header id='site-header' className={`${styles.header_wrapper} ${fill ? styles.background_color : ''}`}>
			<div className={`container ${styles.header}`}>
				<FocusTrap active={activeMenu}>
					<div className={styles.header_content}>
						<button
							className={`${styles.menu_toggle} ${activeMenu ? styles.active : ''}`}
							onClick={toggleMenu}
							type='button'
							aria-label='Toggle Mobile Navigation Menu'
							aria-expanded={activeMenu ? true : false}
							aria-controls='mobile-navigation-menu'
						>
							<span className={styles.menu_bar}></span>
							<span className={styles.menu_bar}></span>
							<span className={styles.menu_bar}></span>
						</button>
						<HeaderMenu isMobile={true} links={menu?.links} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
					</div>
				</FocusTrap>
			</div>
		</header>
	);
};
