// react | next
import Link from 'next/link';
import { useRouter } from 'next/router';
// interface | types
import type { IHeaderMenu } from './IHeaderMenu';
// components
import { MenuLink } from '../MenuLink';
import { LogoFullPrimaryLight } from '@components/Svgs/LogoFullPrimaryLight/LogoFullPrimaryLight';
// styles
import styles from '../Menu.module.scss';

export const HeaderMenu = ({ isMobile, links, activeMenu, setActiveMenu }: IHeaderMenu) => {
	const router = useRouter();

	const toggleMenu = () => {
		if (activeMenu) {
			setActiveMenu(false);
		}
	};

	return (
		<nav className={styles.navbar} role='navigation' aria-label='Site Navigation'>
			<div className={styles.navbar_content}>
				<div className={styles.navbar_brand}>
					<Link href='/'>
						<a onClick={toggleMenu} title='Navigate to Home Page'>
							<LogoFullPrimaryLight height='65px' />
						</a>
					</Link>
				</div>
				<div
					className={`
						${styles.navbar_menu}
						${!isMobile ? styles.hidden : ''}
						${isMobile ? styles.mobile : ''}
						${isMobile && activeMenu ? styles.active : ''}
					`}
					id={isMobile ? 'mobile-navigation-menu' : null}
				>
					<ul className={`container ${styles.navbar_nav}`}>
						<li className={`${styles.nav_item} ${router.pathname === '/' ? styles.active : ''} ${styles.mobile_item}`}>
							<Link href='/'>
								<a className={styles.nav_link} onClick={toggleMenu} aria-label='Navigate to Home Page'>
									Home
								</a>
							</Link>
						</li>
						{links?.map(({ path, title }) => (
							<MenuLink path={path} title={title} key={title} onClick={toggleMenu} />
						))}
						<li className={`${styles.nav_item} ${styles.mobile_item}`}>
							<Link href='/apply-now'>
								<a
									className={`button button-quaternary ${styles.mobile_button}`}
									onClick={toggleMenu}
									aria-label='Navigate to Main Application Page'
								>
									Apply Now
								</a>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};
