export const getAppUrl = (): string => {
	let envAppUrl = process.env.NEXT_PUBLIC_APP_URL
		? process.env.NEXT_PUBLIC_APP_URL
		: process.env.NEXT_PUBLIC_VERCEL_URL;

	if (!/^https?:\/\/(.*)/.test(envAppUrl)) {
		envAppUrl = `https://${envAppUrl}`;
	}

	return envAppUrl;
};
